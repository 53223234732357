import React from 'react'
import { Link, graphql } from 'gatsby'
import { get } from 'lodash'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import './articleList.less'
import SEO from '../../components/seo'
import placeholder from '../../images/placeholder.svg'
import Play from '../../images/Icons/play_circle_outline.svg'
import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs'
import Podcast from 'components/Podcast/Podcast'
import Button from 'components/button/Button'
import { sortArticlesByDate } from '../../config/helpers'
import hashFnv32a from '../../utils/cryptoUtil'
import Truncate from '@konforti/react-truncate'
import Done from '../../images/Icons/done.svg'
import { pushPageViewPreventDuplicates } from 'utils/gtmUtils'
import LayoutNew from 'layout/LayoutNew'
import { getPageUrlFromSlug } from 'utils/urlUtils'

type Articles =
  | {
      type: 'normal'
      data: any[]
    }
  | {
      type: 'filtered'
      data: FilteredArticle[]
    }

type FilteredArticle = {
  node: {
    slug: string
    heading: string
    preambleList: { preambleList: string }
    embeddedPlayer: { embedId: string; type: string }
    featuredImage?: { fluid?: { src: string } }
    tags: any
  }
}
interface State {
  filterState: string | null
  podcastUrl: string

  isLoadingMore: boolean
  currentPage: number
  endOfArticles: boolean
}

interface Props {
  data: object
  pageContext: any
  location: {
    pathname: string
  }
}

const PAGE_SIZE = 12

type ListItemProps = {
  location: Props['location']
  allPages: any[]
  value: {
    heading?: string
    preamble?: { preamble: string }
    preambleList?: { preambleList: string }
    slug?: string
    featuredImage?: { fluid?: { src: string } }
    listImage?: { fluid?: { src: string } }
    type: string
  }
}

interface LargeCtaDataProps {
  body: {
    raw: string
  }
  buttonText: string
  image: {
    description?: string
    file: {
      url: string
    }
  }
  linkToExternalUrl: string
}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => <p className="u-marginTz">{children}</p>,
    [BLOCKS.UL_LIST]: (node: any, children: any) => (
      <ul className="List Offer-list u-marginBsm u-marginTxsm">{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => (
      <li className="u-flex u-flexAlignCenter u-marginBsm">
        <Done />
        <p className="u-marginVz u-paddingLsm u-sizeFull u-flexGrow u-flexShrink u-flexBasisZ">
          {' '}
          {node.content[0].content[0].value}
        </p>
      </li>
    ),
  },
}

const JobListItem = ({ value, location, allPages }: ListItemProps) => {
  let preamble
  let image

  if (value?.listImage) {
    image = value?.listImage.fluid?.src
  } else if (value?.featuredImage) {
    image = value?.featuredImage.fluid?.src
  } else {
    image = placeholder
  }

  if (value?.preambleList?.preambleList) {
    preamble = value?.preambleList?.preambleList
  } else if (value?.preamble?.preamble) {
    preamble = value?.preamble?.preamble
  } else {
    preamble = null
  }

  // Replace "\n" with <br />
  if (preamble) {
    preamble = preamble.split('\n').map((i, key) => {
      return (
        <span key={value?.slug + '_' + key}>
          {i}
          <br />
        </span>
      )
    })
  }
  let url =
    value.type == 'Collection'
      ? getPageUrlFromSlug(allPages, value.slug ?? '')
      : `${location.pathname}/${value?.slug}`
  return (
    <li className="collection-item filter-item">
      <article>
        <Link to={url}>
          <img className="u-sizeFull u-colorBgBeige" src={image} />
          <div className="collection-content" data-job="true" data-type={value.type}>
            <h1 className="Heading Heading--h4 Link Link--noUnderline">
              <Truncate lines={3} style={{ wordBreak: 'none', wordWrap: 'none' }}>
                {value?.heading}
              </Truncate>
            </h1>
            <p>{preamble}</p>
          </div>
        </Link>
      </article>
    </li>
  )
}

const LargeCta = (data: LargeCtaDataProps) => {
  return (
    <div className="largeCta">
      <div className="largeCta__wrapper">
        <div className="largeCta__content">
          <div className="largeCta__body">{renderRichText(data.body, options)}</div>
          <a
            href={`${data.linkToExternalUrl}`}
            className="u-marginTsm button button--normal orange"
            target="_blank"
          >
            {data.buttonText}
          </a>
        </div>
        <div className="largeCta__media">
          <img src={data.image.file.url} alt={data.image?.description} />
        </div>
      </div>
    </div>
  )
}

function StandardListItem({ value, location, allPages }: ListItemProps) {
  let preamble
  let image

  if (value?.listImage) {
    image = value?.listImage.fluid?.src
  } else if (value?.featuredImage) {
    image = value?.featuredImage.fluid?.src
  } else {
    image = placeholder
  }

  if (value?.preambleList?.preambleList) {
    preamble = value?.preambleList?.preambleList
  } else if (value?.preamble?.preamble) {
    preamble = value?.preamble?.preamble
  } else {
    preamble = null
  }

  // Replace "\n" with <br />
  if (preamble) {
    preamble = preamble.split('\n').map((i, key) => {
      return (
        <span key={value?.slug + '_' + key}>
          {i}
          <br />
        </span>
      )
    })
  }
  let url =
    value.type == 'Collection'
      ? getPageUrlFromSlug(allPages, value.slug ?? '')
      : `${location.pathname}/${value?.slug}`
  return (
    <li className="collection-item filter-item">
      <article>
        <Link to={url}>
          <img className="u-sizeFull u-colorBgBeige" src={image} />
          <div className="collection-content" data-job="false" data-type={value.type}>
            <h1 className="Heading Heading--h4 Link Link--noUnderline">
              <Truncate
                lines={3}
                style={{
                  wordBreak: 'none',
                  wordWrap: 'none',
                }}
              >
                {value?.heading}
              </Truncate>
            </h1>
            <p>{preamble}</p>
          </div>
        </Link>
      </article>
    </li>
  )
}

class ArticleList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      filterState: null,
      podcastUrl: '',
      isLoadingMore: false,
      currentPage: 1,
      endOfArticles: false,
    }
  }

  componentDidMount() {
    pushPageViewPreventDuplicates()
  }

  onFilterChange = (newFilter: string) => {
    this.setState((state) => ({
      filterState: state.filterState !== newFilter ? newFilter : null,
      currentPage: 1,
    }))
  }

  loadVideo = (id: string, e: any) => {
    e.currentTarget.parentElement.style.paddingBottom = '56.25%'
    e.currentTarget.parentElement.innerHTML = `
    <iframe id="ytplayer" type="text/html" width="100%" allow="autoplay"
    src="https://www.youtube.com/embed/${id}?autoplay=1&origin=https://dik.se"
    frameborder="0"></iframe>
    `
  }

  getArticles = (): Articles => {
    const { data } = this.props

    let listPage = get(data, 'allContentfulArticleListPage.edges', [])
    const [{ node: listArticleContent }] = listPage

    const pageArticles = get(listArticleContent, 'articles')
    if (pageArticles != null && pageArticles.length > 0) {
      return {
        type: 'normal' as const,
        data: sortArticlesByDate(pageArticles) ?? [],
      }
    }
    const filteredArticles: FilteredArticle[] =
      (listArticleContent.articleType == 'Opinion'
        ? get(data, 'allContentfulOpinionArticle.edges')
        : get(data, 'allContentfulArticle.edges')) ?? []
    return {
      type: 'filtered' as const,
      data: sortArticlesByDate(filteredArticles) ?? [],
    }
  }

  renderLoadMore = (articles: Articles['data']) => {
    const { currentPage } = this.state
    if (this.state.currentPage * PAGE_SIZE >= articles.length) {
      return currentPage <= 1 ? null : (
        <p className="u-textCenter u-marginVxlg u-colorTextLight">
          Det finns inga fler artiklar att hämta.
        </p>
      )
    }

    return (
      <Button
        onClick={() => this.setState((state) => ({ currentPage: state.currentPage + 1 }))}
        loaderColor="dark"
        className="button button-border blue u-marginVxlg myPage-article-button u-posCenter"
      >
        Ladda fler
      </Button>
    )
  }

  // This will be for Jobs ( vacancies )
  getAllContentfulJobs = (): FilteredArticle[] => {
    const { data } = this.props
    const allContenfulArticles = get(data, 'allContentfulArticle.edges', [])

    const jobs = allContenfulArticles.filter((item: { node: { type: string } }) => {
      return item.node.type === 'Job'
    })
    return jobs
  }

  renderJobList = (jobs: FilteredArticle[], allPages: any[]) => {
    return jobs.map(
      (
        value: {
          node: {
            slug: any
            heading?: string | undefined
            preamble?: { preamble: string } | undefined
            preambleList?: { preambleList: string } | undefined
            featuredImage?: { fluid?: { src: string } | undefined } | undefined
            type?: string
          }
        },
        index: number,
      ) => {
        const hashedKey = hashFnv32a(value.node.slug) ?? value.node.slug + index
        return (
          <JobListItem
            key={hashedKey}
            value={value.node}
            location={this.props.location}
            allPages={allPages}
          />
        )
      },
    )
  }

  renderFilterButtons = (itemsToFilter: any) => {
    if (!itemsToFilter.length) {
      return null
    } else {
      return (
        <section className="listPage-filter u-paddingVsm Page-main">
          <div className="Page-container">
            {itemsToFilter
              .map((value: { node: { tags: [] } }) => {
                return value.node.tags
              })
              .reduce((acc: [], curr: [] | null) => {
                if (curr != null) {
                  curr.forEach((val) => acc.push(val))
                }
                return acc
              }, [] as any[])
              .reduce((acc: [{ name: string }], curr: { name: string }) => {
                if (!acc.some((tag) => tag.name == curr.name)) {
                  acc.push(curr)
                }
                return acc
              }, [] as any[])
              .map((node: { name: string; id: string }) => {
                return (
                  <button
                    key={hashFnv32a(node.id)}
                    data-filter={node.id}
                    className={`button button-rounded u-marginRxsm u-marginVxsm ${
                      this.state.filterState === node.id ? 'blue' : ''
                    }`}
                    onClick={() => {
                      this.onFilterChange(node.id)
                    }}
                  >
                    {node.name}
                  </button>
                )
              })}
          </div>
        </section>
      )
    }
  }

  render() {
    const data = this.props.data
    const location = this.props.location
    const allPages = get(data, 'allSitePage', [])
    let listPage = get(data, 'allContentfulArticleListPage.edges', [])
    const listArticleContent = listPage[0]?.node
    if (listArticleContent == null) {
      return null
    }

    const articles = this.getArticles()

    const filteredArticles =
      articles.type === 'filtered' && this.state.filterState == null
        ? articles.data
        : articles.data.filter(
            (val) =>
              val.node != null &&
              val.node.tags instanceof Array &&
              val.node.tags.map((tag: any) => tag.id).indexOf(this.state.filterState) > -1,
          )

    const isOpinionList = listArticleContent.articleType === 'Opinion'

    // Fetch job data and prepare jobs filtering
    const allContentfulJobs = this.getAllContentfulJobs()
    let filteredJobs

    // if null, then there is no applicable filter selected
    // otherwise, we will filter all jobs to jobs with a tag array.
    // Then we will map each tag inside that array to a new array and see if we can find the selected filter inside that array
    // so that we can show only the jobs with the corresponding tags
    if (this.state.filterState === null) {
      filteredJobs = allContentfulJobs
    } else {
      filteredJobs = allContentfulJobs.filter(
        (jobItem: {
          node: {
            tags: []
          }
        }) => {
          if (jobItem.node !== null && jobItem.node.tags.length) {
            const tags = jobItem.node.tags.map((tag: { id: string | null }) => {
              return tag.id
            })

            return tags.indexOf(this.state.filterState) > -1
          }
        },
      )
    }

    const bodyText = listArticleContent.bodyText
    const largeCTAData =
      listArticleContent?.largeCta &&
      listArticleContent.largeCta.type === 'Large' &&
      listArticleContent.largeCta

    return (
      <LayoutNew>
        <SEO
          description={get(listArticleContent, 'metaDescription.metaDescription')}
          title={get(listArticleContent, 'metaTitle')}
          image={get(listArticleContent, 'heroImage.fluid.src')}
        />
        <div
          style={{
            paddingTop: '3rem',
            paddingBottom: '3rem',
          }}
        >
          <div
            style={{
              marginBottom: '2rem',
            }}
          >
            <Breadcrumb breadPath={location.pathname} />
          </div>
          <section className="Page-container">
            <div className="Grid u-flexJustifySpaceBetween hero-block">
              <div className="u-lg-size6of12 u-flex u-flexDirectionColumn u-flexJustifyCenter">
                <h1 className="Heading Heading--h1 u-marginBsm">
                  {get(listArticleContent, 'heading')}
                </h1>
                {bodyText != null ? (
                  renderRichText(bodyText, options)
                ) : (
                  <p>{get(listArticleContent, 'preamble.preamble')}</p>
                )}
                <div className="listPage-buttonGroup">
                  {listArticleContent?.primaryButtonLink !== null && (
                    <a
                      href={`${listArticleContent.primaryButtonLink}`}
                      className="button button--normal orange listPage-buttonGroup__button u-marginRsm"
                    >
                      {listArticleContent.primaryButtonText}
                    </a>
                  )}
                  {listArticleContent?.secondaryButtonText !== null && (
                    <a
                      href={`${listArticleContent.secondaryButtonLink}`}
                      className="button button--normal button-border blue listPage-buttonGroup__button"
                    >
                      {listArticleContent.secondaryButtonText}
                    </a>
                  )}
                </div>
              </div>
              <div className="u-md-size6of12 listPage-image">
                <img src={get(listArticleContent, 'heroImage.fluid.src')} />
              </div>
            </div>
          </section>
        </div>
        {
          // Filter Buttons
          isOpinionList && articles && articles.type === 'filtered' && articles.data?.length && (
            <section className="listPage-filter u-paddingVsm Page-main">
              <div className="Page-container">
                {this.renderFilterButtons(get(data, 'allContentfulOpinionArticle.edges'))}
              </div>
            </section>
          )
        }
        {
          // Job filter Buttons . Render null to avoid "0" as being written into DOM if statement is false
          allContentfulJobs &&
          allContentfulJobs.length &&
          listArticleContent.articleType === 'Job' &&
          listArticleContent.type === 'List' ? (
            <section className="listPage-filter u-paddingVsm Page-main">
              <div className="Page-container">{this.renderFilterButtons(allContentfulJobs)}</div>
            </section>
          ) : null
        }
        <section className="Page-main u-sizeFullVw u-colorBgWhite u-posCenter u-flexGrow">
          <div className="Page-container grid-container">
            <ul id="filter-container" className="filter-container">
              {
                // Grid & items
                isOpinionList &&
                  articles.type === 'filtered' &&
                  filteredArticles
                    .slice(0, this.state.currentPage * PAGE_SIZE)
                    .map((value: FilteredArticle) => (
                      <li
                        className={`${
                          value.node.embeddedPlayer && value.node.embeddedPlayer.type == 'Video'
                            ? 'filter-item filter-item--width2'
                            : 'filter-item'
                        }`}
                        key={value.node.slug}
                      >
                        <article>
                          {value.node.embeddedPlayer &&
                          value.node.embeddedPlayer.type == 'Video' ? (
                            // Video
                            <React.Fragment>
                              <div className="list-video">
                                <button
                                  className="list-video-button"
                                  onClick={(e) => {
                                    this.loadVideo(value.node.embeddedPlayer.embedId, e)
                                  }}
                                >
                                  <Play width="100" height="100" className="list-video-play" />
                                  <img
                                    className="u-colorBgBeige"
                                    src={get(
                                      value,
                                      'node.embeddedPlayer.coverImage.fluid.src' ||
                                        value.node.featuredImage?.fluid?.src ||
                                        `https://img.youtube.com/vi/${value.node.embeddedPlayer.embedId}/maxresdefault.jpg`,
                                    )}
                                  />
                                </button>
                              </div>
                              <h1 className="Heading Heading--h4 u-marginTsm">
                                <Link
                                  className="Link Link--noUnderline"
                                  to={`${location.pathname}/${value.node.slug}`}
                                >
                                  {value.node.heading}
                                </Link>
                              </h1>
                            </React.Fragment>
                          ) : value.node.embeddedPlayer &&
                            value.node.embeddedPlayer.type == 'Podcast' ? (
                            // Podcast
                            <Podcast podId={value.node.embeddedPlayer.embedId}>
                              <h1 className="Heading Heading--h4">
                                <Link
                                  className="Link Link--noUnderline"
                                  to={`${location.pathname}/${value.node.slug}`}
                                >
                                  {value.node.heading}
                                </Link>
                              </h1>
                            </Podcast>
                          ) : (
                            // Else
                            <Link
                              className="u-inlineBlock u-textEllipsis"
                              to={`${location.pathname}/${value.node.slug}`}
                            >
                              <img
                                className="u-sizeFull u-colorBgBeige"
                                src={
                                  value.node.featuredImage
                                    ? get(value, 'node.featuredImage.fluid.src')
                                    : placeholder
                                }
                              />
                              <h1 className="Heading Heading--h4 Link Link--noUnderline u-marginTsm">
                                {value.node.heading}
                              </h1>
                              {value.node.preambleList ? (
                                <p>{get(value, 'node.preambleList.preambleList')}</p>
                              ) : (
                                ''
                              )}
                            </Link>
                          )}
                        </article>
                      </li>
                    ))
              }
              {
                // Or type != List
                listArticleContent.type !== 'List' &&
                  articles &&
                  articles.type === 'normal' &&
                  articles.data?.length &&
                  articles.data.map((value, index: number) => {
                    return (
                      <StandardListItem
                        key={index}
                        value={value}
                        location={location}
                        allPages={allPages}
                      />
                    )
                  })
              }
              {
                // if type is List
                (listArticleContent.type == 'List') !==
                  (listArticleContent.articleType == 'Opinion' ||
                    listArticleContent.articleType == 'Job') &&
                  articles &&
                  articles.type === 'filtered' &&
                  articles.data?.length &&
                  articles.data.map((value, index: number) => {
                    const hashedKey = hashFnv32a(value.node.slug) ?? value.node.slug + index
                    return (
                      <StandardListItem
                        key={hashedKey}
                        value={value.node}
                        location={location}
                        allPages={allPages}
                      />
                    )
                  })
              }
              {
                // if type is Job, unfiltered
                listArticleContent.type == 'List' &&
                  listArticleContent.articleType == 'Job' &&
                  allContentfulJobs &&
                  !filteredJobs &&
                  allContentfulJobs.length &&
                  this.renderJobList(allContentfulJobs, allPages)
              }
              {
                // if type is Job, filtered
                listArticleContent.type == 'List' &&
                  listArticleContent.articleType == 'Job' &&
                  filteredJobs &&
                  filteredJobs.length &&
                  this.renderJobList(filteredJobs, allPages)
              }
            </ul>

            {articles &&
              articles.type === 'filtered' &&
              listArticleContent.articleType !== 'Job' &&
              this.renderLoadMore(filteredArticles)}
          </div>
          {largeCTAData && <LargeCta {...largeCTAData} />}
        </section>
      </LayoutNew>
    )
  }
}

export default ArticleList

export const queryArticleList = graphql`
  query ($type: String, $slug: String, $articletype: String) {
    allSitePage {
      edges {
        node {
          path
          context {
            slug
          }
        }
      }
    }
    allContentfulArticleListPage(filter: { type: { eq: $type }, slug: { eq: $slug } }) {
      edges {
        node {
          navigationName
          articleType
          type
          bodyText {
            raw
          }
          heading
          preamble {
            preamble
          }
          preambleList {
            preambleList
          }
          primaryButtonText
          primaryButtonLink
          secondaryButtonText
          secondaryButtonLink
          listImage {
            fluid(quality: 100) {
              src
            }
          }
          heroImage {
            fluid(quality: 100) {
              src
            }
          }
          metaTitle
          metaDescription {
            metaDescription
          }
          largeCta {
            type
            body {
              raw
            }
            buttonText
            image {
              description
              file {
                url
              }
            }
            linkToExternalUrl
          }
          articles {
            ... on ContentfulArticle {
              navigationName
              slug
              type
              heading
              publishDate
              preamble {
                preamble
              }
              preambleList {
                preambleList
              }
              featuredImage {
                fluid(maxWidth: 400, quality: 100) {
                  src
                }
              }
              listImage {
                fluid(maxWidth: 400, quality: 100) {
                  src
                }
              }
            }
            ... on ContentfulArticleListPage {
              slug
              type
              heading
              preamble {
                preamble
              }
              preambleList {
                preambleList
              }
              featuredImage: heroImage {
                fluid(maxWidth: 400, quality: 100) {
                  src
                }
              }
              listImage {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
    allContentfulOpinionArticle {
      edges {
        node {
          slug
          heading
          publishDate
          preamble {
            preamble
          }
          preambleList {
            preambleList
          }
          type
          tags {
            type
            name
            id
          }
          featuredImage {
            fluid(quality: 100) {
              src
            }
          }
          # embeddedPlayer {
          #   embedId
          #   type
          #   coverImage {
          #     fluid(quality: 100) {
          #       src
          #     }
          #   }
          # }
        }
      }
    }
    allContentfulArticle(filter: { type: { eq: $articletype } }) {
      edges {
        node {
          slug
          heading
          publishDate
          preamble {
            preamble
          }
          preambleList {
            preambleList
          }
          type
          tags {
            type
            name
            id
          }
          embeddedPlayer {
            id
          }
          featuredImage {
            fluid(quality: 100) {
              src
            }
          }
          listImage {
            fluid(maxWidth: 400, quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
